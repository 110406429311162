const components: Record<string, any> = {
  'page': () => import('../../../packages/ui/src/page/src/index.vue'),
  'page_foreign': () => import('../../../packages/ui/src/pageForeign/src/index.vue'),
  'container': () => import('../../../packages/ui/src/container/src/Container.vue'),
  'button': () => import('../../../packages/ui/src/button/src/index.vue'),
  'text': () => import('../../../packages/ui/src/text/src/index.vue'),
  'img': () => import('../../../packages/ui/src/img/src/index.vue'),
  'dialog': () => import('../../../packages/ui/src/dialog/src/index.vue'),
  'qrcode': () => import('../../../packages/ui/src/qrcode/src/index.vue'),
  'overlay': () => import('../../../packages/ui/src/overlay/src/index.vue'),
  'pay_list': () => import('../../../packages/ui/src/payList/src/index.vue'),
  'sign': () => import('../../../packages/ui/src/sign/src/index.vue'),
  'sign_calendar': () => import('../../../packages/ui/src/signCalendar/src/index.vue'),
  'sign_btn': () => import('../../../packages/ui/src/signBtn/src/index.vue'),
  'sign_foreign': () => import('../../../packages/ui/src/signForeign/src/index.vue'),
  'task': () => import('../../../packages/ui/src/task/src/index.vue'),
  'task1': () => import('../../../packages/ui/src/task1/src/index.vue'),
  'task_out': () => import('../../../packages/ui/src/taskOut/src/index.vue'),
  'task_combine': () => import('../../../packages/ui/src/taskCombine/src/index.vue'),
  'task_block': () => import('../../../packages/ui/src/taskBlock/src/index.vue'),
  'appoint': () => import('../../../packages/ui/src/appoint/src/index.vue'),
  'appoint_people': () => import('../../../packages/ui/src/appointPeople/src/index.vue'),
  'rule_dialog': () => import('../../../packages/ui/src/ruleDialog/src/index.vue'),
  'wechat': () => import('../../../packages/ui/src/wechat/src/index.vue'),
  'pre_dialog': () => import('../../../packages/ui/src/preDialog/src/index.vue'),
  'lottery_share_img': () => import('../../../packages/ui/src/lotteryShareImg/src/index.vue'),
  'invite_rebate': () => import('../../../packages/ui/src/inviteRebate/src/index.vue'),
  'invite_rebate_team': () => import('../../../packages/ui/src/inviteRebateTeam/src/index.vue'),
  'invite_rebate_list': () => import('../../../packages/ui/src/inviteRebateList/src/index.vue'),
  'invite_rebate_btn': () => import('../../../packages/ui/src/inviteRebateBtn/src/index.vue'),
  'invite_rebate_code': () => import('../../../packages/ui/src/inviteRebateCode/src/index.vue'),
  'exchange': () => import('../../../packages/ui/src/exchange/src/index.vue'),
  'exchange_btn': () => import('../../../packages/ui/src/exchangeBtn/src/index.vue'),
  'exchange_eight': () => import('../../../packages/ui/src/exchangeEight/src/index.vue'),
  'exchange_page': () => import('../../../packages/ui/src/exchangePage/src/index.vue'),
  'exchange_three': () => import('../../../packages/ui/src/exchangeThree/src/index.vue'),
  'lottery': () => import('../../../packages/ui/src/lottery/src/index.vue'),
  'lottery_14': () => import('../../../packages/ui/src/lottery14/src/index.vue'),
  'lottery_cdkey': () => import('../../../packages/ui/src/lotteryCDkey/src/index.vue'),
  'lottery_switch_3': () => import('../../../packages/ui/src/lotterySwitch3/src/index.vue'),
  'lottery_notice': () => import('../../../packages/ui/src/lotteryNotice/src/index.vue'),
  'scratch_ticket': () => import('../../../packages/ui/src/scratchTicket/src/index.vue'),
  'switch_photo': () => import('../../../packages/ui/src/switchPhoto/src/index.vue'),
  'switch_photo_role': () => import('../../../packages/ui/src/switchPhotoRole/src/index.vue'),
  'switch_photo_vertical': () => import('../../../packages/ui/src/switchPhotoVertical/src/index.vue'),
  'menu_side': () => import('../../../packages/ui/src/menuSide/src/index.vue'),
  'ranking': () => import('../../../packages/ui/src/ranking/src/index.vue'),
  'login': () => import('../../../packages/ui/src/login/src/index.vue'),
  'login_foreign': () => import('../../../packages/ui/src/loginForeign/src/index.vue'),
  'condition_photo': () => import('../../../packages/ui/src/conditionPhoto/src/index.vue'),
  'empty_block': () => import('../../../packages/ui/src/emptyBlock/src/index.vue'),
  'new_server': () => import('../../../packages/ui/src/newServer/src/index.vue'),
  'unit_num': () => import('../../../packages/ui/src/unitNum/src/index.vue'),

  'page-fragment-container': () => import(
    '../../../packages/ui/src/page-fragment-container/src/PageFragmentContainer.vue',
  ),

  'page-fragment': () => import('../../../packages/ui/src/page-fragment/src/PageFragment.vue'),
  'facebook_like_button': () => import('../../../packages/ui/src/facebookLikeButton/src/index.vue'),
  'video_player': () => import('../../../packages/ui/src/videoPlayer/src/index.vue'),
};

export default components;